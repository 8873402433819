/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeSelector } from '../../../store/feature/storeSlice';
import { MultiSelect } from 'react-multi-select-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { cartAttributes, cartSelector } from '../../../store/feature/cartSlice';
import AttributesList from './AttributesList';

const CartAttributes = ({
  attributeData,
  setAttributeData,
  setShowError,
  setErrormessage,
  styles,
  item_attributes,
  setItemAttributes,
  cart_details,
  data,
}) => {
  const dispatch = useDispatch();
  // functions
  useEffect(() => {
    if (cart_details?.[0]?.quantity) {
      setItemAttributes(
        Array.from({ length: cart_details?.[0]?.quantity }, () => [])
      );
    }

    dispatch(
      cartAttributes({
        prams: {
          type: 'cart_attributes',
        },
      })
    );
  }, [cart_details]);

  const { cart_attributes } = useSelector(cartSelector);
  const [displayColorPicker, setdisplayColorPicker] = useState(null);

  const fileUploadClick = (id) => {
    let fileInput = document.getElementById(`fileUploadClick-${id}`);
    fileInput.click();
  };
  return (
    <div className="w-full " style={get_dynamic_styles(styles?.main_styles)}>
      {cart_details &&
        item_attributes.length > 0 &&
        item_attributes.map((item_attr, index) => {
          return (
            <>
              <p className="text-primary text-xl leading-6 font-medium ">
                Passenger Info
              </p>
              <AttributesList
                cart_attributes={cart_attributes}
                data={data}
                styles={styles}
                attributeData={item_attributes[index]}
                setAttributeData={(data) =>
                  setItemAttributes((prev) =>
                    prev.map((it, ind) => (ind !== index ? it : data))
                  )
                }
              />
            </>
          );
        })}
    </div>
  );
};

export default CartAttributes;
